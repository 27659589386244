import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import { share } from '@/static/js/share.js';
import { user_info } from '@/api/user.js';
import { oauth } from "@/static/js/oauth";
Vue.use(VueRouter)

//路由守卫Bug解决方案
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/pages/index/index'),
        meta: {
            title: '崆峒山年卡',
            keepAlive: true,
        }
    },
    {
        path: '/s/:sale_id?',
        to: '/',
        name: 'index2',
        component: () => import('@/pages/index/index'),
        meta: {
            title: '崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '//s/:sale_id?',
        to: '/',
        name: 'index3',
        component: () => import('@/pages/index/index'),
        meta: {
            title: '崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('@/pages/order/index'),
        meta: {
            title: '订单-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/order_detail',
        name: 'order_detail',
        component: () => import('@/pages/order/detail'),
        meta: {
            title: '订单详情-崆峒山年卡',
            keepAlive: false
        }
    },
    {
        path: '/query',
        name: 'query',
        component: () => import('@/pages/user/query/index'),
        meta: {
            title: '年卡查询-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/bug',
        name: 'bug',
        component: () => import('@/pages/user/bug/index'),
        meta: {
            title: '意见反馈-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/bug_list',
        name: 'bug_list',
        component: () => import('@/pages/user/bug/list'),
        meta: {
            title: '反馈记录-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/bug_detail',
        name: 'bug_detail',
        component: () => import('@/pages/user/bug/detail'),
        meta: {
            title: '反馈详情-崆峒山年卡',
            keepAlive: false
        }
    },
    {
        path: '/collect',
        name: 'collect',
        component: () => import('@/pages/other/collect'),
        meta: {
            title: '收款-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '//collect',
        name: 'collect2',
        component: () => import('@/pages/other/collect'),
        meta: {
            title: '收款-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/qr',
        name: 'qr1',
        component: () => import('@/pages/other/qr'),
        meta: {
            title: '核验码-崆峒山年卡',
            keepAlive: false
        }
    },
    {
        path: '/qr/:code',
        name: 'qr2',
        component: () => import('@/pages/other/qr'),
        meta: {
            title: '核验码-崆峒山年卡',
            keepAlive: false
        }
    },
    {
        path: '/photo/:id',
        name: 'photo',
        component: () => import('@/pages/other/photo'),
        meta: {
            title: '电子卡-崆峒山年卡',
            keepAlive: false
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/pages/other/contact'),
        meta: {
            title: '联系我们-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/page',
        name: 'page',
        component: () => import('@/pages/other/page'),
        meta: {
            title: '页面',
            keepAlive: false
        }
    },
    {
        path: '/page/:id',
        name: 'page2',
        component: () => import('@/pages/other/page'),
        meta: {
            title: '页面',
            keepAlive: false
        }
    },
    {
        path: '/set',
        name: 'set',
        component: () => import('@/pages/user/set/index'),
        meta: {
            title: '系统设置-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/sale',
        name: 'sale',
        component: () => import('@/pages/sale/index'),
        meta: {
            title: '推广中心-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/sale_qr',
        name: 'sale_qr',
        component: () => import('@/pages/sale/qr'),
        meta: {
            title: '推广码-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/sale_rank',
        name: 'sale_rank',
        component: () => import('@/pages/sale/rank'),
        meta: {
            title: '部门排名-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/sale_detail',
        name: 'sale_detail',
        component: () => import('@/pages/sale/detail'),
        meta: {
            title: '推广详情-崆峒山年卡',
            keepAlive: false
        }
    },
    {
        path: '/sale_ticket',
        name: 'sale_ticket',
        component: () => import('@/pages/sale/ticket'),
        meta: {
            title: '推广套票-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/sale_view',
        name: 'sale_view',
        component: () => import('@/pages/sale/view'),
        meta: {
            title: '访问记录-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/sale_child',
        name: 'sale_child',
        component: () => import('@/pages/sale/child'),
        meta: {
            title: '下级代理-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/sale_withdraw',
        name: 'sale_withdraw',
        component: () => import('@/pages/sale/withdraw'),
        meta: {
            title: '结算列表-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/sale_withdraw_detail',
        name: 'sale_withdraw_detail',
        component: () => import('@/pages/sale/withdraw_detail'),
        meta: {
            title: '结算详情-崆峒山年卡',
            keepAlive: false
        }
    },
    {
        path: '/company',
        name: 'company',
        component: () => import('@/pages/company/index'),
        meta: {
            title: '我的企业-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/company_apply',
        name: 'company_apply',
        component: () => import('@/pages/company/apply'),
        meta: {
            title: '企业申请-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/company_user',
        name: 'company_user',
        component: () => import('@/pages/company/user'),
        meta: {
            title: '企业用户-崆峒山年卡',
            keepAlive: false
        }
    },
    {
        path: '/companysubmit/:id?',
        name: 'companysubmit',
        component: () => import('@/pages/index/index'),
        meta: {
            title: '提交资料-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '//companysubmit/:id?',
        name: 'companysubmit2',
        component: () => import('@/pages/index/index'),
        meta: {
            title: '提交资料-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/person',
        name: 'person',
        component: () => import('@/pages/index/index'),
        meta: {
            title: '年卡办理-崆峒山年卡',
            keepAlive: false
        }
    },
    {
        path: '//person',
        name: 'person2',
        component: () => import('@/pages/index/index'),
        meta: {
            title: '年卡办理-崆峒山年卡',
            keepAlive: false
        }
    },
    {
        path: '/package',
        name: 'package',
        component: () => import('@/pages/package/index'),
        meta: {
            title: '套票购买-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/package_detail',
        name: 'package_detail',
        component: () => import('@/pages/package/detail'),
        meta: {
            title: '套票详情-崆峒山年卡',
            keepAlive: false
        }
    },
    {
        path: '/package_success',
        name: 'package_success',
        component: () => import('@/pages/package/success'),
        meta: {
            title: '支付状态-崆峒山年卡',
            keepAlive: true
        }
    },
    {
        path: '/balance',
        name: 'balance',
        component: () => import('@/pages/sale/balance'),
        meta: {
            title: '佣金提现-崆峒山年卡',
            keepAlive: false
        }
    },
    {
        path: '/cert',
        name: 'cert',
        component: () => import('@/pages/sale/cert'),
        meta: {
            title: '实名认证-崆峒山年卡',
            keepAlive: false
        }
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: () => import('@/pages/company/invoice'),
        meta: {
            title: '发票申请-崆峒山年卡',
            keepAlive: false
        }
    },
    {
        path: '*',
        name: '404',
        component: () => import('@/pages/index/404'),
        meta: {
            title: '页面不存在',
            keepAlive: true,
        }
    },

]

const router = new VueRouter({
    routes,
    mode: 'history',
})


function is_wechat() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}

//URL参数转换为JSON对象
function urlParamToJson(url) {
    let json = {};
    if(url.indexOf('?')!==-1){
        url.split('?')[1].trim()
            .split('&')
            .forEach(item => json[item.split('=')[0]] = item.split('=')[1]);

    }
    return json;
}



router.beforeEach((to, from, next) => {

    oauth();

    //微信授权写入
    const local_unionid = store.getters.unionid;
    const unionid = urlParamToJson(window.location.href)['get_unionid'];
    if (!local_unionid && unionid) {
        store.commit('setUnionid', unionid);
        user_info({ unionid: unionid }).then(res => {
            if (res.code == 1) {
                store.commit('setOpenid', res.data.wechat_openid);
                store.commit('setUser', res.data);
            }
        });
    }

    //设置头部
    document.title = to.meta.title
    store.commit('setLast', from.path);

    //设置统一的分享
    share(to.fullPath, to.meta.title);

    next();
})
export default router
