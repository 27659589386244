import { common_share , common_address} from '@/api/common.js';
import config2 from '@/static/js/config.js';
import store from '@/store';
// var jweixin = require('jweixin-module');
import jweixin from 'weixin-js-sdk';
//定义配置参数
let config = {};

//获取配置参数
function get_wechat(url, title) {
    common_share({ url: url }).then(res => {
        config = res.data.signPackage;
        if (config) {
            init(url, title);
        }
    });
}


function init(url, title) {
    let param = {
        debug: false, // 开启调试模式
        appId: config.appId, // 必填，公众号的唯一标识
        timestamp: config.timestamp, // 必填，生成签名的时间戳
        nonceStr: config.nonceStr, // 必填，生成签名的随机串
        signature: config.signature, // 必填，签名
        jsApiList: [
            'checkJsApi', //判断当前客户端版本是否支持指定JS接口
            'onMenuShareTimeline', //分享到朋友圈
            'onMenuShareAppMessage', //分享给好友
            'getLocation',//获取位置
        ] // 必填，需要使用的JS接口列表
    };

    jweixin.config(param);
    jweixin.ready(function () {
        // 分享到朋友圈
        jweixin.onMenuShareTimeline({
            title: title,
            link: config2.url + url,
            desc: '欢迎使用崆峒山年卡！',
            imgUrl: config2.url + require('@/static/image/logo.jpg'),
            type: 'link',
            dataUrl: '',
            success: function () {

            },
            cancel: function () {

            }
        });

        //分享给好友
        jweixin.onMenuShareAppMessage({
            title: title,
            link: config2.url + url,
            desc: '欢迎使用崆峒山年卡！',
            imgUrl: config2.url + require('@/static/image/logo.jpg'),
            success: function () {

            },
            cancel: function () {

            }
        });

        //获取位置
        jweixin.getLocation({
            type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: function (res) {
                common_address({
                    latitude:res.latitude,
                    longitude:res.longitude,
                }).then(res2=>{
                    if(res2.code==1){
                        const location = {
                            latitude:res.latitude,
                            longitude:res.longitude,
                            address:res2.data.result.formatted_addresses.recommend,
                            result:res2.data.result,
                        };
                        if(res2.data.status==0){
                            store.commit('setLocation',location);
                        }
                    }
                });
            },
        });
    });
}

//执行入口
export function share(url, title) {
    if (url == '/sale_child') {
        return false;
    }
    get_wechat(url, title);
}